import { render, staticRenderFns } from "./MegaMenuSheetDesktop.vue?vue&type=template&id=90d53338&scoped=true&"
import script from "./MegaMenuSheetDesktop.vue?vue&type=script&lang=js&"
export * from "./MegaMenuSheetDesktop.vue?vue&type=script&lang=js&"
import style0 from "./MegaMenuSheetDesktop.vue?vue&type=style&index=0&id=90d53338&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "90d53338",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseImage: require('/home/gitlab-runner/builds/3h9sKVRF/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/BaseImage.vue').default,BaseIcon: require('/home/gitlab-runner/builds/3h9sKVRF/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/BaseIcon.vue').default,MegaMenuSheetDesktopLink: require('/home/gitlab-runner/builds/3h9sKVRF/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/MegaMenu/Sheet/Desktop/MegaMenuSheetDesktopLink.vue').default,BaseButton: require('/home/gitlab-runner/builds/3h9sKVRF/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/BaseButton.vue').default,MegaMenuSheetDesktopItem: require('/home/gitlab-runner/builds/3h9sKVRF/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/MegaMenu/Sheet/Desktop/MegaMenuSheetDesktopItem.vue').default})
